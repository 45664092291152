import React from 'react';
import styles from './Contact.module.css';
import rightbg from '../../Assets/conrigtbg.mp4';

const Contact = () => {
  return (
    <>
      <div className={styles.contact}>
        <div className={styles.contact_head}>
          <h1 className={styles.absolute}>Contact Us</h1>
        </div>

        <div className={styles.contact_form}>
          <div className={styles.left}>
            <div className={styles.contact_contact}>
            
              <div className={styles.contact_contact_form}>
                <form>
                  <div className={styles.contact_contact_form_top}>
                    <input type="text" name="first_name" placeholder="First Name" required />
                    <input type="text" name="last_name" placeholder="Last Name" required />
                  </div>

                  <div className={styles.contact_contact_form_top}>
                    <input type="email" name="email" placeholder="Email" required />
                    <input type="tel" name="phone" placeholder="Phone Number" required />
                  </div>

                  <select name="category" required>
                    <option value="" disabled selected>Category</option>
                    <option value="residential">Residential Construction</option>
                    <option value="commercial">Commercial Construction </option>
                    <option value="renovation">Renovation and Remodeling</option>
                    <option value="interior">Interior Design</option>
                  </select>
                  <textarea name="message" placeholder="Your Message" required></textarea>
                  <input type="submit" value="Submit" className={styles.submit_contact_form} />
                </form>
              </div>
            </div>
          </div>

          <div className={styles.right}>
            <video src={rightbg} autoPlay muted loop />
            <div className={styles.contact_details}>
              <a href='tel:+919800800349' style={{color:'white', padding:'0', margin:'0'}}><li><div className={styles.circle}><i className="fa-solid fa-phone"></i></div>+919800800349</li></a>
             <a href="mailto:saurabh.pal@palconstruction.in" style={{color:'white', padding:'0', margin:'0'}}> <li><div className={styles.circle}><i className="fa-solid fa-envelope"></i></div>saurabh.pal@palconstruction.in</li></a>
              <li><div className={styles.circle}><i className="fa-solid fa-location-dot"></i></div> Yewlewadi Saswad road,Pune</li>
            </div>
          </div>

        </div>

      </div>
    </>
  );
}

export default Contact;
