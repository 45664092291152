import React, { useState } from 'react';
import styles from './Gototop.module.css'; 

const Gototop = () => {
  const [isVisible, setIsVisible] = useState(false);

  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  
  const handleScroll = () => {
    const scrollTop = window.pageYOffset;
    if (scrollTop > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Add scroll event listener to window
  // This will trigger the handleScroll function when the user scrolls
  // and update the visibility of the button accordingly
  window.addEventListener('scroll', handleScroll);

  return (
    <button
      className={`${styles.goToTopButton} ${isVisible ? styles.visible : styles.hidden}`} // Use classNames to conditionally apply styles
      onClick={scrollToTop}
      title="Go to Top"
    >
     &#8593;
    </button>
  );
};

export default Gototop;
