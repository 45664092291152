import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Navbar from './Components/Navbar/Navbar'
import Home from './Components/Home/Home'
import Footer from './Components/Footer/Footer'
import About from './Components/About/About'
import Services from './Components/Services/Services'
import Gototop from './Components/Gototop/Gototop'
import ScrollToTop from './Components/ScrollToTop'
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import Logo from './Assets/Logo.png'
import Contact from './Components/Contact/Contact'


const App = () => {
  return (
    <>
      <BrowserRouter basename='/'> {/* this is just example  basename='/palconstruction'  for production u just need to use '/' */}
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/services' element={<Services />} />
          <Route path='/contact' element={<Contact />} />
        </Routes>
        <Gototop />
        <FloatingWhatsApp phoneNumber="+918805310284" accountName="Pal Construction" avatar={Logo} darkMode={false} />
        <Footer />
      </BrowserRouter>
    </>
  )
}

export default App
