import React from 'react';
import styles from './About.module.css';
import mission from '../../Assets/mission.png';
import vision from '../../Assets/vision.png';
import goal from '../../Assets/goal.png';
import historyImage from '../../Assets/Gallery5.webp';
import teamImage from '../../Assets/contact.jpg';
import ScrollAnimation from 'react-animate-on-scroll';

const About = () => {
    return (
        <>
            <div className={styles.about}>
                {/* About Hero */}
                <div className={styles.about_hero} role="banner">
                    <div className={styles.about_hero_heading}>
                        <h1>About Us</h1>
                    </div>
                </div>

                {/* Intro */}
                <div className={styles.about_intro} role="main">
                    <ScrollAnimation animateIn='bounceInLeft'>
                        <div className={styles.intro_heading}>
                            <h2>Welcome to Pal Construction</h2>
                        </div>
                    </ScrollAnimation>
                    <div className={styles.intro_paragraph}>
                        <ScrollAnimation animateIn='bounceInRight'>
                            <p>At Pal Construction, we believe in turning dreams into reality. With a passion for craftsmanship and a commitment to excellence, we strive to bring your vision to life, one brick at a time.</p>
                            <p>With years of experience in the construction industry, we have earned a reputation for delivering high-quality projects, on time and within budget. From residential homes to commercial spaces, no project is too big or too small for our dedicated team of professionals.</p>
                            <p>Our mission is simple: to build spaces that inspire, innovate, and endure. Whether you're embarking on a new construction project or renovating an existing property, we're here to guide you every step of the way.</p>
                            <p>At Pal Construction, we don't just build structures; we build relationships. We understand that every project is unique, and we pride ourselves on our personalized approach, tailored to meet your specific needs and aspirations.</p>
                            <p>Explore our portfolio, envision the possibilities, and let's embark on this journey together. Welcome to Pal Construction, where dreams take shape and excellence is our foundation.</p>
                        </ScrollAnimation>
                    </div>
                </div>

                {/* History */}
                <div className={styles.history_section}>
                    <ScrollAnimation animateIn='fadeInUp'>
                        <div className={styles.history_content}>
                            <h2>Our History</h2>
                            <img src={historyImage} alt='Our History' />
                            <p>Founded in 2000, Pal Construction started as a small family-owned business with a mission to deliver quality construction services. Over the years, we have grown into a leading construction company in Pune, known for our dedication to quality, innovation, and customer satisfaction.</p>
                        </div>
                    </ScrollAnimation>
                </div>

                {/* Mission, Vision, Goals */}
                <div className={styles.mvg_main}>
                    <div className={styles.mvg_grid}>
                        <ScrollAnimation animateIn='fadeInUp'>
                            <div className={styles.mvg_grid_box}>
                                <div className={styles.image_holder}>
                                    <img src={mission} alt='Pal Construction Mission' />
                                </div>
                                <div className={styles.mvg_content_holder}>
                                    <h2>Our Mission</h2>
                                    <p>At Pal Construction, we craft enduring spaces with a focus on quality, craftsmanship, and customer satisfaction, always delivering on time and within budget.</p>
                                </div>
                            </div>
                        </ScrollAnimation>

                        <ScrollAnimation animateIn='fadeInUp' delay={200}>
                            <div className={styles.mvg_grid_box}>
                                <div className={styles.image_holder}>
                                    <img src={vision} alt='Pal Construction Vision' />
                                </div>
                                <div className={styles.mvg_content_holder}>
                                    <h2>Our Vision</h2>
                                    <p>To be the leading construction company, transforming dreams into reality with exceptional quality, innovation, and integrity.</p>
                                </div>
                            </div>
                        </ScrollAnimation>

                        <ScrollAnimation animateIn='fadeInUp' delay={300}>
                            <div className={styles.mvg_grid_box}>
                                <div className={styles.image_holder}>
                                    <img src={goal} alt='Pal Construction Goal' />
                                </div>
                                <div className={styles.mvg_content_holder}>
                                    <h2>Our Goals</h2>
                                    <p>To continuously enhance our services and processes, expanding our portfolio while prioritizing safety, sustainability, and client satisfaction.</p>
                                </div>
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>

                {/* Team */}


                {/* Testimonials */}
                <div className={styles.testimonials_section}>
                    <ScrollAnimation animateIn='fadeInUp'>
                        <div className={styles.testimonials_content}>
                            <h2>Client Testimonials</h2>
                            <blockquote>
                                <p>"Pal Construction exceeded our expectations in every way. Their attention to detail and commitment to quality made our dream home a reality."</p>
                                <footer>— Happy Client</footer>
                            </blockquote>
                            <blockquote>
                                <p>"Working with Pal Construction was a seamless experience. Their professional approach and dedication to timelines ensured our project was completed on time and within budget."</p>
                                <footer>— Satisfied Customer</footer>
                            </blockquote>
                        </div>
                    </ScrollAnimation>
                </div>
            </div>
        </>
    );
}

export default About;
