import React, { useState, useEffect } from 'react'
import styles from './Navbar.module.css'
import logo from '../../Assets/Logo.png'
import { Link } from 'react-router-dom'

const Navbar = () => {
    const [isScroll, setIsScroll] = useState(false);
    const [scrollPos, setScrollPos] = useState(0);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleScroll = () => {
        const currentScrollPos = window.pageYOffset;
        const isScrollingDown = currentScrollPos > scrollPos;

        if (isScrollingDown && currentScrollPos > 100) {
            setIsScroll(true);
        } else {
            setIsScroll(false);
        }

        setScrollPos(currentScrollPos);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [scrollPos]);


    const ToggleMenu = () =>{
        setIsMenuOpen(!isMenuOpen);
        
    }
    const closemenu = () => {
        setIsMenuOpen(false);
    }

    return (
        <>
            <div className={`${styles.header} ${isScroll ? styles.hide_menu : ''}`}>
                <div className={styles.navbar}>
                    <div className={styles.logo}>
                        <img src={logo} alt='Pal Construction'/>
                    </div>

                    <div className={`${styles.menu} ${isMenuOpen ? styles.menuopen : ''} `}>
                        <li><Link to='/' className={styles.links} onClick={closemenu}>Home</Link></li>
                        <li><Link to='/about' className={styles.links} onClick={closemenu}>About</Link></li>
                        <li><Link to='/services' className={styles.links} onClick={closemenu}>Services</Link></li>
                        <li><Link to='/contact' className={`${styles.links} ${styles.contact_button} `} onClick={closemenu}>Contact</Link></li>
                    </div>

                    <div className={styles.bars} onClick={ToggleMenu}>
                        <div className={styles.bar}></div>
                        <div className={styles.bar}></div>
                        <div className={styles.bar}></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Navbar
