import React from 'react';
import styles from './Footer.module.css';
import logo from '../../Assets/Logo.png'
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <section className={styles.footer_example_4}>
        <footer className={styles.footer_distributed}>

          <div className={styles.footer_left}>
                <img src={logo}  alt='Pal Construction LOGO'/>
            <p className={styles.footer_links}>
                <Link to='/'><a className={styles.link_1}>Home</a></Link>
                <Link to='/about' >About</Link>
                <Link to='/services' >Services</Link>
                <Link to='/contact' >Contact</Link>
            </p>

            <p className={styles.footer_company_name}>Pal Consturction © 2024</p>
           
          </div>

          <div className={styles.footer_center}>

            <div>
              <i className="fa fa-map-marker"></i>
              <p><span>Yewlewadi Kondwa BK,</span>Pune - 411048</p>
            </div>

            <div>
              <i className="fa fa-phone"></i>
              <p><a href='tel:+919800800349' style={{color:'white'}}>+919800800349</a></p>
            </div>

            <div>
              <i className="fa fa-envelope"></i>
              <p><a href="mailto:saurabh.pal@palconstruction.in" style={{color:'white'}}>saurabh.pal@palconstruction.in</a></p>
            </div>

          </div>

          <div className={styles.footer_right}>

            <p className={styles.footer_company_about}>
              <span>About the company</span>
              Pal Construction is a leading construction company dedicated to excellence in building projects, committed to delivering exceptional quality and customer satisfaction.
            </p>

            <div className={styles.footer_icons}>
              
              <a><i className="fa fa-instagram"></i></a>
              <a><i className="fa fa-facebook"></i></a>
              <a><i className="fa fa-twitter"></i></a>
              <a><i className="fa fa-linkedin"></i></a>
              
            </div>

          </div>
          

        </footer>
        <div className={styles.developer}>
          <a target='_blank' href='https://codeaxe.in'>Design and Developed by Codex</a>
          </div>
      </section>
    </>
  );
};

export default Footer;
