import React from 'react'
import Hero from './Hero/Hero'
import HomeWelcome from './HomeWelcome/HomeWelcome'

const Home = () => {
  return (
    <>
    <Hero/>
    <HomeWelcome/>
    </>
  )
}

export default Home