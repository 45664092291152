import React from 'react';
import styles from './Services.module.css';

import residensial from '../../Assets/recidencial.webp';
import commercial from '../../Assets/commercial.webp';
import renovation from '../../Assets/Renovation.webp';
import interior from '../../Assets/Interior.webp';
import infra from '../../Assets/infra.webp';
import havy from '../../Assets/Heavy.webp';
import { Link } from 'react-router-dom';

const services = [
    {
        title: "Residential Construction",
        description: "Creating new homes tailored to your vision and lifestyle.",
        image: residensial,
    },
    {
        title: "Commercial Construction",
        description: "Building functional and aesthetically pleasing commercial spaces.",
        image: commercial,
    },
    {
        title: "Renovation and Remodeling",
        description: "Transforming your existing space into the home of your dreams.",
        image: renovation,
    },
    {
        title: "Interior Design",
        description: "Creating cohesive and inviting interiors that reflect your taste.",
        image: interior,
    },
    {
        title: "Industrial Construction",
        description: "Building factories, warehouses, power plants, and other industrial facilities.",
        image: infra,
    },
    {
        title: "Heavy Civil Construction",
        description: "Large-scale construction projects such as dams, highways, and airports.",
        image: havy,
    },
];

const testimonials = [
    {
        name: "Adhiraj",
        feedback: "Pal Construction did an amazing job on our new home. The attention to detail and quality of work were outstanding. Highly recommend!"
    },
    {
        name: "BNR Group",
        feedback: "Their team was professional, efficient, and delivered on time. We couldn't be happier with our new office space."
    }
];

const faqs = [
    {
        question: "What services do you offer?",
        answer: "We offer residential construction, commercial construction, renovation and remodeling, and interior design."
    },
    {
        question: "How can I get a quote?",
        answer: "You can contact us through our website or call us to discuss your project and get a free quote."
    }
];

const Services = () => {
    return (
        <>
            <div className={styles.services_head}>
                <div className={styles.services_head_bg}>
                    Our Services
                </div>
            </div>
            <div className={styles.services}>
                <div className={styles.header}>
                    <h1>Our Services</h1>
                    <p>Explore the range of services we offer to bring your vision to life.</p>
                </div>
                <div className={styles.cards}>
                    {services.map((service, index) => (
                        <div key={index} className={styles.card}>
                            <div className={styles.card_image_holder}>
                            <img src={service.image} alt={service.title} className={styles.cardImage} />
                            </div>
                           
                            <h2 className={styles.cardTitle}>{service.title}</h2>
                            <p className={styles.cardDescription}>{service.description}</p>
                        </div>
                    ))}
                </div>
                
                <div className={styles.process}>
                    <h2>Our Process</h2>
                    <p>Learn about our step-by-step approach to ensure your project is a success.</p>
                    <ol>
                        <li className={styles.bg_1}> <div className={styles.loader}></div> <li>Initial Consultation</li></li>
                        <li className={styles.bg_1}> <div className={styles.loader}></div> <li> Planning and Design</li></li>
                        <li className={styles.bg_1}> <div className={styles.loader}></div> <li>Approval and Permits</li></li>
                        <li className={styles.bg_1}> <div className={styles.loader}></div> <li>Construction</li></li>
                        <li className={styles.bg_1}> <div className={styles.loader}></div> <li>Final Inspection Handover</li></li>
                    </ol>
                </div>
                <div className={styles.testimonials}>
                    <h2>Client Testimonials</h2>
                    <div className={styles.testimonialCards}>
                        {testimonials.map((testimonial, index) => (
                            <div key={index} className={styles.testimonialCard}>
                                <p>"{testimonial.feedback}"</p>
                                <h3>- {testimonial.name}</h3>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.faq}>
                    <h2>Frequently Asked Questions</h2>
                    <div className={styles.faqItems}>
                        {faqs.map((faq, index) => (
                            <div key={index} className={styles.faqItem}>
                                <h3>{faq.question}</h3>
                                <p>{faq.answer}</p>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.cta}>
                    <h2>Ready to build your dream space?</h2>
                    <button><Link className={styles.contact_button} to='/contact' >Contact Us</Link></button>
                </div>
            </div>
        </>
    );
};

export default Services;
