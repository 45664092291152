import React, { useState } from 'react'
import styles from './HomeWelcome.module.css'
import ScrollAnimation from 'react-animate-on-scroll';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
// gallery
import gallery1 from '../../../Assets/Gallery1.webp'
import gallery2 from '../../../Assets/Gallery2.webp'
import gallery3 from '../../../Assets/Gallery3.webp'
import gallery4 from '../../../Assets/Gallery4.webp'
import gallery5 from '../../../Assets/Gallery5.webp'
import gallery6 from '../../../Assets/Gallery6.webp'
import { Link } from 'react-router-dom';
import office from '../../../Assets/home_welcom.png';

const HomeWelcome = () => {
  const images = [gallery1, gallery2, gallery3, gallery4, gallery5, gallery6];
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000
  };

  return (
    <>
      <div className={styles.homewelcome}>
        <div className={styles.homewelcome_flex}>
          <div className={styles.homewelcome_head}>
            <ScrollAnimation animateIn='fadeInUp'>
              <h2>Welcome to Pal Construction.</h2>
              <p>At Pal Construction, we turn dreams into reality with a commitment to excellence and craftsmanship. Our experienced team delivers high-quality residential and commercial projects on time and within budget. Let us guide you through your construction journey, creating spaces that inspire and endure. Welcome to Pal Construction, where dreams take shape.</p>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div className={styles.homewelcome_contactdetails}>
                  <i className="fa fa-phone"></i><p> <a href='tel:+919800800349'>+919800800349</a></p>
                </div>
                <div className={styles.homewelcome_contactdetails}>
                  <i className="fa fa-envelope"></i><p><a href="mailto:info@palconstruction.in">saurabh.pal@palconstruction.in</a></p>
                </div>
              </div>
            </ScrollAnimation>
          </div>
          <div className={styles.homewelcome_office}>
            <img src={office} alt='Pal Construction Office' />
          </div>
        </div>

        {/* Services */}

        <div className={styles.home_services}>
          <h2>Our Services</h2>
          <div className={styles.home_services_container}>
            <div className={styles.left}>
              <img src={gallery6} alt='Pal Construction' />
            </div>
            <div className={styles.right}>
              {/* Point 1 */}
              <ScrollAnimation animateIn='fadeInRight'>
                <div className={styles.service_points}>
                  <div className={styles.outer_circle}>
                    <div className={styles.inner_circle}>
                    </div>
                  </div>
                  <div className={styles.service_point_content}>
                    <p>Residential Construction</p>
                    <i style={{ display: 'flex', alignItems: 'center' }}>
                      <Link to='/services' style={{ textDecoration: 'none', listStyle: 'none', color: 'blue' }}>Read More</Link>
                      {'->'}
                    </i>
                  </div>
                </div>
              </ScrollAnimation>

              {/* Point 2 */}
              <ScrollAnimation animateIn='fadeInRight'>
                <div className={styles.service_points}>
                  <div className={styles.outer_circle}>
                    <div className={styles.inner_circle}>
                    </div>
                  </div>
                  <div className={styles.service_point_content}>
                    <p>Commercial Construction</p>
                    <i style={{ display: 'flex', alignItems: 'center' }}>
                      <Link to='/services' style={{ textDecoration: 'none', listStyle: 'none', color: 'blue' }}>Read More</Link>
                      {'->'}
                    </i>

                  </div>
                </div>
              </ScrollAnimation>

              {/* Point 3 */}
              <ScrollAnimation animateIn='fadeInRight'>
                <div className={styles.service_points}>
                  <div className={styles.outer_circle}>
                    <div className={styles.inner_circle}>
                    </div>
                  </div>
                  <div className={styles.service_point_content}>
                    <p>Renovation and Remodeling</p>
                    <i style={{ display: 'flex', alignItems: 'center' }}>
                      <Link to='/services' style={{ textDecoration: 'none', listStyle: 'none', color: 'blue' }}>Read More</Link>
                      {'->'}
                    </i>
                  </div>
                </div>
              </ScrollAnimation>

              {/* Point 4 */}
              <ScrollAnimation animateIn='fadeInRight'>
                <div className={styles.service_points}>
                  <div className={styles.outer_circle}>
                    <div className={styles.inner_circle}>
                    </div>
                  </div>
                  <div className={styles.service_point_content}>
                    <p>Interior Design</p>
                    <i style={{ display: 'flex', alignItems: 'center' }}>
                      <Link to='/services' style={{ textDecoration: 'none', listStyle: 'none', color: 'blue' }}>Read More</Link>
                      {'->'}
                    </i>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </div>


        {/* Why Choose Us */}

        <section id={styles.why_choose_us} className={styles.why_choose_us}>
          <div className={styles.container}>
            <div className={styles.why_choose_us_head}>
              <h2>Why Choose Us</h2>
            </div>

            <div className={styles.points}>
              <ScrollAnimation animateIn='fadeInUp'>
                <div className={styles.point}>
                  <i className="fa fa-users"></i>
                  <h3>Experienced Team</h3>
                  <p>Our team has years of experience in the construction industry, ensuring that every project is handled with expertise and professionalism.</p>
                </div>
              </ScrollAnimation>

              <ScrollAnimation animateIn='fadeInUp'>
                <div className={styles.point}>
                  <i className="fa fa-star"></i>
                  <h3>Quality Craftsmanship</h3>
                  <p>We are committed to delivering high-quality workmanship on every project, using the best materials and adhering to the highest standards.</p>
                </div>
              </ScrollAnimation>


              <ScrollAnimation animateIn='fadeInUp'>
                <div className={styles.point}>
                  <i className="fa fa-clock"></i>
                  <h3>Timely Completion</h3>
                  <p>We understand the importance of deadlines and ensure that all projects are completed on time, without compromising on quality.</p>
                </div>
              </ScrollAnimation>

              <ScrollAnimation animateIn='fadeInUp'>
                <div className={styles.point}>
                  <i className="fa fa-money"></i>
                  <h3>Budget-Friendly Solutions</h3>
                  <p>We provide cost-effective solutions without sacrificing quality, ensuring that your project stays within budget.</p>
                </div>
              </ScrollAnimation>

              <ScrollAnimation animateIn='fadeInUp'>
                <div className={styles.point}>
                  <i className="fa fa-heart"></i>
                  <h3>Customer-Centric Approach</h3>
                  <p>We prioritize our clients' needs and work closely with them throughout the project to ensure their vision is realized.</p>
                </div>
              </ScrollAnimation>

              <ScrollAnimation animateIn='fadeInUp'>
                <div className={styles.point}>
                  <i className="fa fa-wrench"></i>
                  <h3>Comprehensive Services</h3>
                  <p>From new construction to renovations and commercial projects, we offer a wide range of services to meet all your construction needs.</p>
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </section>



        {/* Gallery  */}
        <div className={styles.gallery}>
          <div className={styles.gallery_head}>
            <h2>Latest Photos</h2>
          </div>
          <div className={styles.gallery_greed}>
            {images.map((image, index) => (
              <div
                key={index}
                className={styles.gallery_grid_box}
                onClick={() => {
                  setPhotoIndex(index);
                  setIsOpen(true);
                }}
              >
                <img src={image} alt={`Pal Construction Gallery ${index + 1}`} />
              </div>
            ))}
          </div>
        </div>

        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        )}

        {/* Clients */}

        <div className={styles.clients}>
          <div className={styles.emp_card_holder}>
            <h2>Clients Message </h2>
            <Slider {...sliderSettings}>
              <div className={styles.client_message}>
                <p>"Pal Construction did an amazing job on our new home. The attention to detail and quality of work were outstanding. Highly recommend!" - Adhiraj</p>
              </div>
              <div className={styles.client_message}>
                <p>"Their team was professional, efficient, and delivered on time. We couldn't be happier with our new office space." - BNR Group </p>
              </div>
              <div className={styles.client_message}>
                <p>"From start to finish, Pal Construction was a pleasure to work with. They transformed our old house into a beautiful modern home." - Sumitra </p>
              </div>
            </Slider>
          </div>
        </div>


        {/* Contact */}
        <div className={styles.home_newsletter}>
          <div className={styles.home_contact_flex}>
            <div className={`${styles.box} ${styles.box_left}`}>
              <div className={styles.box_head}>
                <h2>JOIN OUR NEWSLETTER</h2>
                <p>Stay Updated with Pal Constructions!</p>
                <p>Are you passionate about the latest trends in construction and interior design? Do you want exclusive insights into our newest projects, renovation tips, and expert advice on building your dream space? Join our newsletter and be part of the Pal Constructions community!</p>
                <div className={styles.news_icons}>
                  <i className="fa fa-phone"></i><p><a href='tel:+919800800349'>+919800800349</a></p>
                </div>
                <div className={styles.news_icons}>
                  <i className="fa fa-envelope"></i><p><a href="mailto:saurabh.pal@palconstruction.in">saurabh.pal@palconstruction.in</a></p>
                </div>
              </div>
            </div>
            {/*  */}
            <div className={`${styles.box} ${styles.box_right}`}>
            <div className={styles.box_right_form}>
              <input type='text' placeholder='Enter Your Name' required />
              <input type='text' placeholder='Enter Your Email' required />
              <textarea placeholder='Enter Your Message ' required />
              <input type='submit' value='Submit' className={styles.news_letter_submitbtn}/>

            </div>
          </div>
          </div>

         
        </div>

      </div>
    </>
  )
}

export default HomeWelcome